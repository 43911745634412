<template>
    <div class="wrok-theme-create">
        <div class="manage-wrapper">
            <div class="content">
                <div class="myTabs">
                    <el-tabs v-model="activeName">
                        <el-tab-pane :label="$route.query.isEdit == 'true' ? '编辑报告' : '报告'" name="kimi">
                        </el-tab-pane>
                    </el-tabs>
                    <div class="export_box flex-align">
                        <el-radio-group v-model="radio1" size="mini" @input="changeType">
                            <el-radio-button label="0"
                                v-if="$route.query.reportType == 3 && $route.query.status == 2">预览</el-radio-button>
                            <el-radio-button label="1">阿里报告</el-radio-button>
                            <el-radio-button label="2">kimi报告</el-radio-button>
                            <el-radio-button label="3">
                                <i class="el-icon-refresh-left"></i>
                                重新生成(阿里)
                            </el-radio-button>
                            <el-radio-button label="4">
                                <i class="el-icon-refresh-left"></i>
                                重新生成(kimi)
                            </el-radio-button>
                            <el-radio-button label="5">
                                <i class="el-icon-download"></i>
                                导出
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="box flex-between">
                    <div class="user_box flex-column" v-if="$route.query.reportType == 3">
                        <div class="title">学员名单</div>
                        <el-input placeholder="请输入学员姓名" size="small" prefix-icon="el-icon-search" v-model="queryName"
                            @input="nameChange">
                        </el-input>
                        <div class="user_item flex-align" :class="{ 'user_item_active': user_index == index }"
                            v-for="(item, index) in stuList" :key="index" @click="selectUser(item, index)">
                            <img src="@/assets/images/home/avatar.png" alt="">
                            <span class="wes">{{ item.name }}</span>
                        </div>
                        <div class="pagination">
                            <el-pagination layout="prev,pager,next" small :total="allSize" :pager-count="5"
                                :page-size="pagesize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChange">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="kimi_box">
                        <div class="view_content" v-if="radio1 == '0'">
                            <div class="title-box flex-align-center">
                                <div class="flex-column-center">
                                    <span>研修学习数据统计与分析</span>
                                    <span>（{{ title }}）</span>
                                </div>
                                <el-select v-model="value" placeholder="请选择" size="mini" @change="changeValue">
                                    <el-option v-for="item in options" :key="item.cycleId" :label="item.cycleName"
                                        :value="item.cycleId">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="step-box flex-align">
                                <span class="icon"></span>
                                一、本周统计
                            </div>
                            <el-table :data="tableData" border style="width: 100%">
                                <el-table-column label="观/听总数" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.viewAndListenNumber ? scope.row.viewAndListenNumber : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="思总数" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.thinkNumber ? scope.row.thinkNumber : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="问总数" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.askNumber ? scope.row.askNumber : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="思&问相关个数" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.thinkAndAskRelNumber ? scope.row.thinkAndAskRelNumber : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="考勤完成总次数" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.joinNumber ? scope.row.joinNumber : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="综合得分" align="center">
                                    <template slot-scope="scope">
                                        {{ (scope.row.viewAndListenNumber + scope.row.thinkNumber + scope.row.askNumber +
                                            scope.row.thinkAndAskRelNumber) * 20 + (scope.row.joinNumber * 10) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="content" v-for="(item, index) in list" :key="index">
                                <div class="step-box flex-align">
                                    <span class="icon"></span>
                                    {{ nums[index + 2] + '、' + item.publishTitle }}
                                </div>
                                <div class="item-box flex-column">
                                    <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="item.content" readonly>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="kimi_content flex-column" v-else v-loading="loading">
                            <div class="title_box flex-align">
                                <div class="title">{{ $route.query.title }}</div>
                            </div>
                            <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="kimi_detail" :readonly="readonly"
                                v-if="kimi_detail">
                            </el-input>
                            <el-empty description="暂无报告" v-else></el-empty>
                        </div>
                    </div>
                </div>
                <div class="flex-align-around" style="margin-top: 10px;" v-if="$route.query.isEdit == 'true'">
                    <el-button type="primary" @click="edit">完成</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { exportWord } from '../../../utils/exportWord'
import {
    mapState
} from "vuex";
export default {
    components: {
        VueMarkdown
    },
    data() {
        return {
            activeName: 'kimi',
            // userInfo: this.$store.state.user,
            loading: false,
            input: '',
            queryName: '',
            type: '',
            allSize: 0,
            user_index: 0,
            history_index: 0,
            historyList: [],
            pagesize: 7,
            currentPage: 1,
            stuList: [],
            currentUserId: '',
            parentId: '',
            kimi_detail: '',
            msgId: null,
            readonly: this.$route.query.isEdit == 'true' ? false : true,
            radio1: (this.$route.query.reportType == 3 && this.$route.query.status == 2) ? '0' : '1',
            reportType: 1,


            tableData: [],
            value: '',
            options: [],
            content2: '',
            content3: '',
            title: '',
            title2: '',
            title3: '',
            sort: 0,
            list: [],
            nums: {
                1: '一',
                2: '二',
                3: '三',
                4: '四',
                5: '五',
                6: '六',
                7: '七',
                8: '八',
                9: '九',
                10: '十',
            }
        }
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
            userInfo: state => state.user.userInfo,
        })
    },
    methods: {
        handleCurrentChange(page) {
            this.currentPage = page;
            this.user_index = 0;
            this.reportUserList();
        },
        // 选择用户
        selectUser(item, index) {
            this.user_index = index;
            this.currentUserId = item.id;
            this.tableData = []
            if (this.radio1 == 0) {
                // this.queryNewestCycleList();
                this.queryStatistics();
                this.userReport()
            } else {
                this.reportAiLast();
            }
        },
        // 获取学员名单
        async reportUserList() {
            let data = {
                reportId: this.$route.query.reportId,
                page: this.currentPage,
                limit: this.pagesize,
                queryName: this.queryName
            }
            let resData = await this.$Api.kimi.reportUserList(data);
            console.log('学员列表:', resData);
            this.allSize = resData.data.total;
            this.stuList = resData.data.records;
            this.currentUserId = resData.data.records[0].id;
            this.reportAiLast();
        },
        nameChange(name) {
            this.queryName = name.trim();
            this.currentPage = 1;
            this.reportUserList()
        },
        // 获取报告
        async reportAiLast() {
            this.kimi_detail = '';
            let data = {
                reportId: this.$route.query.reportId,
                userId: this.$route.query.reportType == 3 ? this.currentUserId : '',
                modelType: this.reportType,
            }
            let resData = await this.$Api.kimi.reportAiLast(data);
            console.log('获取报告:', resData);
            this.loading = false;
            if (resData.data) {
                this.kimi_detail = resData.data.content;
                this.parentId = resData.data.id;
                this.msgId = resData.data.parentId
            };

            if (this.radio1 == 0) {
                this.queryNewestCycleList()
            }
        },
        // 编辑报告
        async edit() {
            let data = {
                id: this.parentId,
                content: this.kimi_detail,
            }
            let resData = await this.$Api.kimi.editRreport(data);
            console.log('编辑报告:', resData);
            if (resData.code == 200 && resData.data === true) {
                this.$notify.success({
                    title: "编辑成功",
                });
            }
        },
        export_word(content) {
            exportWord(content.split('\n'), this.$route.query.title)
        },
        // 重新生成
        async refresh(type) {
            let data = {
                reportId: this.$route.query.reportId,
                msgId: this.msgId,
                userId: this.currentUserId,
                type: type ? type : ''
            }
            this.loading = true;
            let resData = await this.$Api.kimi.getReportResult(data);
            console.log('重新生成:', resData);
            this.reportAiLast();
            try {
                if (resData.slice(0, 6) == '<html>') {
                    this.loading = false;
                }
            } catch (err) {
                console.log(err)
            }

        },
        // 切换报告类型
        changeType(type) {
            console.log('type:', type)
            console.log('typeof:', typeof (type))

            switch (type) {
                case '0':
                    console.log('预览报告');
                    break
                case '1':
                    this.reportType = type;
                    this.reportAiLast();
                    break;
                case '2':
                    this.reportType = '';
                    this.reportAiLast();
                    break;
                case '3':
                    this.refresh(1);
                    break;
                case '4':
                    this.refresh('');
                    break;
                case '5':
                    this.export_word(this.kimi_detail);
                    break;
            }
        },





        changeValue(val) {
            console.log('val', val);
            this.tableData = [];
            this.title = this.options.find(item => {
                return item.cycleId == val
            }).cycleName;
            if (val) {
                this.queryStatistics()
            } else {
                this.queryStatistics('step')
            }
            this.userReport()
        },
        async queryStatistics(step) {
            let data = {
                projectId: this.currentProject.id,
                cycleId: this.value
            }
            let resData = await this.$Api.kimi.queryStatistics(data);
            console.log('数据统计:', resData);
            if (step) {
                this.tableData.push(resData.data.wholeData)
            } else {
                this.tableData.push(resData.data.currentWeekData)
            }
        },
        async queryNewestCycleList() {
            let data = {
                projectId: this.currentProject.id
            }
            let resData = await this.$Api.kimi.queryNewestCycleList(data);
            // console.log('获取周列表:', resData);
            if (resData.data.length > 0) {
                this.options = resData.data;
                this.value = resData.data[0].cycleId;
                this.title = resData.data[0].cycleName;
                this.queryStatistics();
                this.userReport()
            }

        },
        async userReport() {
            let data = {
                periodId: this.value,
                projectId: this.currentProject.id,
                status: 2,
                userId: this.$route.query.reportType == 3 ? this.currentUserId : '',
            }
            let resData = await this.$Api.kimi.userReport(data);
            console.log('学习报告:', resData);
            this.list = resData.data.sort((a, b) => a.publishSort - b.publishSort);
        },
    },
    mounted() {
        if (this.$route.query.reportType == 3) {
            this.reportUserList();
        } else {
            this.reportAiLast();
        }
        if($route.query.status ==2){
            
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./less/view_kimi.less');
</style>